import GraphShared from "../../shared/GraphShared/GraphShared";
import { useEffect, useState } from "react";
import "./kpi.style.scss";
import ModelPopUp from "../../shared/models/model-popup/ModelPopUp";
import RedArrow from "../../assets/img/Line 92.png";
import GreenArrow from "../../assets/img/Line 93.png";
import EditKpiDetail from "../../shared/models/edit-kpi-details/EditKpiDetails";
import EmptyDataSign from "../../assets/img/empty-data.svg";
import axios from "axios";
import Spinner from "../../shared/Spinner/Spinner";
import { capitalizeFirstLetter } from "../../util/util";
import KpiColumnEdit from "../../shared/models/column-edit-details/ColumnEdit";
import DelelteKpiField from "../../shared/models/deleteKpi/DeleteKpiField";

interface IProps {
  userId: any;
}

const KpiPage = ({ userId }: IProps) => {
  const [finalData, setFinalData] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [show, setShow] = useState(false);
  const [isActionSelected, setIsActionSelected] = useState("");
  const [graphTitle, setGraphTitle] = useState("");
  const [kpiData, setKpiData] = useState<any>([]);
  const [activeId, setActiveId] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [targetGraphArr, setTargetGraphArr] = useState<any>([]);
  const [actulTargetGraphArr, setActualGraphArr] = useState<any>([]);
  const [flagUpdated, setFlagUpdated] = useState(false);
  const [showEditColumnModel, setShowEditColumnModel] = useState(false);
  const [colName, setColName] = useState("");
  const [editColumnDetails, setEditColumnDetails] = useState([]);
  const [deleteKpiModelShow, setDeleteKpiModelShow] = useState(false);

  const graphData = {
    labels: ["Now", "Phase 1", "Phase 2", "Phase 3"],
    datasets: [
      {
        label: "Phases",
        data: actulTargetGraphArr.slice(2, 6),
        fill: true,
        borderColor: "#27AE60",
      },
      {
        label: "Data",
        data: targetGraphArr.slice(2, 6),
        fill: false,
        borderColor: "#EB5757",
      },
    ],
  };
  /**
   * @param arr -array of obejct
   * @param id -identify the specific item in object
   * @returns graphActiveTitle name
   */
  const setActiveKpiTitleHandler = (arr: any, id: any) => {
    setGraphTitle(
      arr
        .map((item: any) => {
          if (item.id === id) {
            return capitalizeFirstLetter(item.kpiMetricDetail);
          }
        })
        .join("")
    );
  };

  /**
   * @param activeArray - -- array of object
   * @param id -identify the specific item in object
   * onClick handler supplies new Arrary to graph & actual, target
   */

  const getAllHealthValue = (activeArray?: any, id?: any) => {
    getActualAndTargetHandler(activeArray, id);
    if (window.innerWidth < 992) {
      setShowModel(true);
    }
    setActiveKpiTitleHandler(activeArray, id);
  };

  /**
   * @getKpiDetails using @api_call
   */

  const getKpiDetails = () => {
    if (userId) {
    setLoading(true);
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/kpi?userId=${userId}`, {
          headers: {
            Authorization: sessionStorage.getItem("AUTH_TOKEN") || "",
          },
        })
        .then((response) => {
          setLoading(false);
          setKpiData(response.data.data);
          getActualAndTargetHandler(
            response.data.data,
            response.data.data[0]?.id
          );
          setActiveKpiTitleHandler(
            response.data.data,
            response.data.data[0]?.id
          );
          setActiveId(response.data.data[0]?.id);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  /**
   *
   * @param arr
   * @param id
   * @returns two array (actual & target & final also)
   */
  const getActualAndTargetHandler = (arr: any, id: any) => {
    let actulArr: any = [];
    let targetArr: any = [];
    let finalKpiData: any = [];
    arr.map((item: any) => {
      if (item.id == id) {
        targetArr.push(item.id);
        targetArr.push(item.kpiMetricDetail);
        targetArr.push(item.now);
        targetArr.push(item.phase1);
        targetArr.push(item.phase2);
        targetArr.push(item.phase3);
        targetArr.push(item.unit);
        actulArr.push(item.id);
        actulArr.push(item.kpiMetricDetail);
        actulArr.push(item.now);
        actulArr.push(item.actualPhase1);
        actulArr.push(item.actualPhase2);
        actulArr.push(item.actualPhase3);
        actulArr.push(item.unit);
      }
    });
    for (let i = 0; i < targetArr.length; i++) {
      if (actulArr[i] !== 0) {
        finalKpiData.push(actulArr[i]);
      } else {
        finalKpiData.push(targetArr[i]);
      }
    }
    setTargetGraphArr(targetArr);
    setActualGraphArr(actulArr);
    setFinalData(finalKpiData);
    setActiveId(id);
    return {
      targetArr: targetArr,
      actualArr: actulArr,
      finalKpiData: finalKpiData,
    };
  };

  /**
   *
   * @param e - inorder to handle to prevent @event Bubbling
   * @param NavigateTabShow  --  boolean (open right canvas slider)
   * @param action  -- identify which action we want perfrom (actionType- addRow, editRow)
   * @param finalArr -- array of object
   * @param id  -- id for identify unique item for @specificObject
   */
  const isNavigationOpen = (
    e: any,
    NavigateTabShow: boolean,
    action: any,
    finalArr?: any,
    id?: any
  ) => {
    e.stopPropagation();
    setIsActionSelected(action);
    setShow(NavigateTabShow);
    getActualAndTargetHandler(finalArr, id);
    setActiveKpiTitleHandler(finalArr, id);
  };

  /**
   * delete item behalf of @id
   * @param e - inorder to handle to prevent @event Bubbling
   * @param id - id for identify unique item for specific object
   */

  const onDeleteKpiFielidData = (e: any, id: any,name:any) => {
    e.stopPropagation();
    setActiveId(id);
    setGraphTitle(name)
    setDeleteKpiModelShow(true);
  };

  /**
   *
   * @param KpiColName -- identify phase's name
   * @param arr --array of objects
   * @param EditColumnModel -- open edit Columns right offcanvas
   */
  const onUpdateColWise = (
    KpiColName: any,
    arr: any,
    EditColumnModel: boolean
  ) => {
    setColName(KpiColName);
    setShowEditColumnModel(EditColumnModel);
    let result = arr.map((item: any) => ({
      id: item.id,
      kpiName: item.kpiMetricDetail,
      value:
        KpiColName == "phase1"
          ? item.actualPhase1 != null
            ? item.actualPhase1
            : item.phase1
          : KpiColName == "phase2"
          ? item.actualPhase2 != null
            ? item.actualPhase2
            : item.phase2
          : KpiColName == "phase3"
          ? item.actualPhase3 != null
            ? item.actualPhase3
            : item.phase3
          : null,
    }));
    setEditColumnDetails(result);
  };
  /**
   *
   * @returns edit fontawesome icon if kpidata response is not empty
   */
  const showIconEditable = () => {
    return !loading && kpiData.length > 0 ? (
      <i className="fa-solid fa-pen edit-icons-kpi"></i>
    ) : (
      <></>
    );
  };

  /**
   * helper function of @onUpdateColWise
   * @param phaseName
   * @param arr --array of object
   * @param showUpdate
   * @returns @onUpdateColWise or @null
   */

  const onUpdateColPhase = (
    phaseName: string,
    arr: any,
    showUpdate: boolean
  ) => {
    return kpiData.length > 0
      ? onUpdateColWise(phaseName, arr, showUpdate)
      : null;
  };

  /**
   * filter the response based on @actual  & @target
   * @param actual
   * @param target
   * @returns
   */
  const showKpiField = (actual: any, target: any) => {
    return actual != null ? actual : target;
  };

  const colourHandler = (actual: any, target: any, flag: boolean) => {
    if (actual === null) {
      return "#676D7C";
    }

    if (flag) {
      return actual >= target ? "#27AE60" : "#EB5757";
    } else {
      return actual <= target ? "#27AE60" : "#EB5757";
    }
  };

  useEffect(() => {
    getKpiDetails();
    setActiveId(kpiData["0"]?.id);
  }, [userId, flagUpdated]);

  return (
    <>
      <div className="MainPannel bgclr">
        <div className="container-fluid">
          {!userId? (
            <img
              className="no-data-found-txt"
              src={EmptyDataSign}
              alt="empty data img"
            />
          ) : loading ? (
            <Spinner />
          ) : (
            <div className="row blood-result kpi-blood-result mt-0">
              <div className="col-lg-8">
                <div className="headdiv">
                  <h3 className="heading3">KPI</h3>
                  <i
                    className="fa-solid fa-plus text-white add-row-table"
                    onClick={(e: any) => isNavigationOpen(e, true, "AddNewRow")}
                  ></i>
                </div>
                <div className="t-r kpitable table-responsive">

                    <table id="kpitable" className="table">
                      <thead>
                        <tr>
                          <th>KPI Metric</th>
                          <th>Now</th>
                          <th
                            onClick={() =>
                              onUpdateColPhase("phase1", kpiData, true)
                            }
                          >
                            Phase 1{showIconEditable()}
                          </th>
                          <th
                            onClick={() =>
                              onUpdateColPhase("phase2", kpiData, true)
                            }
                          >
                            Phase 2{showIconEditable()}
                          </th>
                          <th
                            onClick={() =>
                              onUpdateColPhase("phase3", kpiData, true)
                            }
                          >
                            Phase 3{showIconEditable()}
                          </th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody className="kpi-pointer-check">
                        {kpiData ? (
                          kpiData.map((item: any, index: any) => {
                            return (
                              <tr
                                key={item.id}
                                onClick={() =>
                                  getAllHealthValue(kpiData, item.id)
                                }
                                className={`${
                                  activeId == item.id ? `isActive-heading kpi-matric-name` : `kpi-matric-name`
                                }`}
                              >
                                <td className="item-kpi-metric-name">
                                  {capitalizeFirstLetter(item.kpiMetricDetail)}
                                </td>
                                <td className="td-kpi-item">
                                  {item.now} <span> {item.unit} </span>
                                </td>
                                <td
                                  className="td-kpi-item"
                                  style={{
                                    color: `${colourHandler(
                                      item.actualPhase1,
                                      item.phase1,
                                      item.phase1 > item.now
                                    )}`,
                                  }}
                                >
                                  {showKpiField(item.actualPhase1, item.phase1)}
                                  <span className="ms-1">{item.unit}</span>
                                </td>
                                <td
                                  className="td-kpi-item"
                                  style={{
                                    color: `${colourHandler(
                                      item.actualPhase2,
                                      item.phase2,
                                      item.phase2 > item.now
                                    )}`,
                                  }}
                                >
                                  {showKpiField(item.actualPhase2, item.phase2)}
                                  <span className="ms-1">{item.unit}</span>
                                </td>
                                <td
                                  className="td-kpi-item"
                                  style={{
                                    color: `${colourHandler(
                                      item.actualPhase3,
                                      item.phase3,
                                      item.phase3 > item.now
                                    )}`,
                                  }}
                                >
                                  {showKpiField(item.actualPhase3, item.phase3)}
                                  <span className="ms-1">{item.unit}</span>
                                </td>
                                <td>
                                  <span className="edit-btn-kpi">
                                    {/* <i
                                      className="fa-solid fa-pen"
                                      onClick={(e) =>
                                        isNavigationOpen(
                                          e,
                                          true,
                                          "editRow",
                                          kpiData,
                                          item.id
                                        )
                                      }
                                    ></i> */}
                                  </span>
                                  <span className="delete-btn-kpi">
                                    <i
                                      className="fa-sharp fa-solid fa-trash"
                                      onClick={(e) =>
                                        onDeleteKpiFielidData(
                                          e,
                                          item.id,
                                          item.kpiMetricDetail
                                        )
                                      }
                                    ></i>
                                  </span>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <></>
                        )}

                        <tr>
                          <th></th>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                </div>
              </div>

              <div className="col-lg-4 kpi-graph-shared KpiTop">
                {!loading && kpiData.length > 0 ? (
                  <>
                    <GraphShared
                      data={graphData}
                      graphTitle={graphTitle}
                      scaleY={true}
                      scaleXFontSize={true}
                    />

                    <br />
                    <img src={RedArrow} alt="green arrow" />
                    <span className="is-target"> Target</span>
                    <br />
                    <img src={GreenArrow} alt="red arrow" />
                    <span className="is-target">Actual</span>
                  </>
                ) : (
                  <></>
                )}

                <ModelPopUp
                  scaleY={true}
                  showValue={showModel}
                  graphData={graphData}
                  showMethod={() => setShowModel(!showModel)}
                  arrow={true}
                  graphTitle={graphTitle}
                  scaleXFontSize={true}
                ></ModelPopUp>
              </div>
            </div>
          )}
        </div>
      </div>
      {show ? (
        <EditKpiDetail
          show={show}
          setFlagUpdated={setFlagUpdated}
          setShow={setShow}
          flagUpdated={flagUpdated}
          data={{
            isActionSelected,
            selectedData: finalData ? finalData.slice(1) : null,
            ItemId: isActionSelected == "AddNewRow" ? null : activeId,
            userId,
          }}
        />
      ) : (
        <></>
      )}
      {showEditColumnModel ? (
        <KpiColumnEdit
          userId={userId}
          colName={colName}
          showEditColumnModel={showEditColumnModel}
          setShowEditColumnModel={setShowEditColumnModel}
          data={editColumnDetails}
          setFlagUpdated={setFlagUpdated}
          flagUpdated={flagUpdated}
        />
      ) : (
        <></>
      )}
      {deleteKpiModelShow ? (
        <DelelteKpiField
          setDeleteKpiModelShow={setDeleteKpiModelShow}
          deleteKpiModelShow={deleteKpiModelShow}
          userId={userId}
          activeId={activeId}
          flagUpdated={flagUpdated}
          setFlagUpdated={setFlagUpdated}
          graphTitle={graphTitle}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default KpiPage;
