import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { lowerCaseString } from "../../../util/util";
import { ToastOnFailure, ToastOnSuccess } from "../../Toast/ToastMsg";

interface IProps {
  showFactorEdit: any;
  setShowFactorEdit: any;
  userId?: any;
  data?: any;
  redirectionFactor?: any;
  scoreCardId?: any;
  flagUpdated?: any;
  setFlagUpdated?: any;
}
const FactorModel = ({
  flagUpdated,
  setFlagUpdated,
  userId,
  data,
  showFactorEdit,
  setShowFactorEdit,
  redirectionFactor,
  scoreCardId,
}: IProps) => {

  useEffect(() => {
    setFactorDetails([{
      name: "Factor 1",
      value: data[0]?.pFactor1 ? data[0]?.pFactor1 : data[0]?.nFactor1,
    },
    {
      name: "Factor 2",
      value: data[0]?.pFactor2 ? data[0]?.pFactor2 : data[0]?.nFactor2,
    },
    {
      name: "Factor 3",
      value: data[0]?.pFactor3 ? data[0]?.pFactor3 : data[0]?.nFactor3,
    }])
  }, [data])

  const [factorDetails, setFactorDetails] = useState<any>([]);


  const getPhaseValue = (phaseName: any) => {
    return factorDetails
      .map((item: any) => (item.name == phaseName ? item.value : null))
      .join("");
  };

  const onUpdateEditField = (e: any, ind: any) => {
    let newArr: { value: any; name: string }[] = [];
    factorDetails.map((item: any, index: any) => {
      if (index == ind) {
        item.value = e.target.value;
      }
      newArr.push(item);
    });
    setFactorDetails(newArr);
  };

  const updateKpiDetails = () => {
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/admin/updateScoreCardFactor`,
        {
          userId: userId,
          scoreCardId: scoreCardId,
          factorType: lowerCaseString(redirectionFactor),
          factor1: getPhaseValue("Factor 1"),
          factor2: getPhaseValue("Factor 2"),
          factor3: getPhaseValue("Factor 3"),
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("AUTH_TOKEN") || "",
          },
        }
      )
      .then((response: any) => {
        setShowFactorEdit(false);
        ToastOnSuccess(response.data.message);
        setFlagUpdated(!flagUpdated);
      })
      .catch((error) => {
        setShowFactorEdit(false);
        console.log(error);
        ToastOnFailure(error.response.data.message);
      });
  };

  return (
    <>
      <Offcanvas
        show={showFactorEdit}
        onHide={() => setShowFactorEdit(false)}
        placement="end"
        className="offcanvas-edit-kpi"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit {redirectionFactor} Factor</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="position-relative">
          <>
            <div className="row">
              <div className="col-3">
                {factorDetails?.map((item: any, index: any) => {
                  return (
                    <div
                      className="input-group input-group-sm mb-3 "
                      key={index}
                    >
                      <span
                        className="input-group-text w-100"
                        id="inputGroup-sizing-sm"
                      >
                        {item.name}
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="col-9">
                {factorDetails?.map((item: any, index: any) => {
                  return (
                    <div
                      className="input-group input-group-sm mb-3"
                      key={index}
                    >
                      <input
                        type="text"
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-sm"
                        name={item.value}
                        value={item.value == null ? "" : item.value}
                        onChange={(e: any) => onUpdateEditField(e, index)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="fixed-bottom-content">
              <Button
                className="fixed-bottom-content-btn"
                variant="light"
                onClick={() => setShowFactorEdit(false)}
              >
                Close
              </Button>
              <Button
                className="fixed-bottom-content-btn"
                variant="dark"
                // disabled={}
                onClick={() => updateKpiDetails()}
              >
                Update
              </Button>
            </div>
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default FactorModel;
