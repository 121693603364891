import BackImg from "../../../assets/img/Back.png";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import GraphShared from "../../../shared/GraphShared/GraphShared";
import "./bloodChild.style.scss";
import ModelPopUp from "../../../shared/models/model-popup/ModelPopUp";
import axios from "axios";
import Spinner from "../../../shared/Spinner/Spinner";
import { formatDate } from "../../../util/util";

interface IProps {
  userId?: any;
}

const BloodResultPage = ({ userId }: IProps) => {
  const [getData, setGetData] = useState<any>();
  const [showModel, setShowModel] = useState(false);
  const [graphTitle, setGraphTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [progressData, setProgressData] = useState<any>();
  const [dateData, setDateData] = useState<any>();
  const [initialData, setInitialData] = useState<any>();

  const showModelOnClick = (title: string) => {
    setGetData(progressData[title]);
    setGraphTitle(title);
    if (window.innerWidth < 992) {
      setShowModel(true);
    }
  };

  /** Status flag function */

  const statusFlag = (e: any) => {
    if (e.results < e.optimalLow) {
      return "Low";
    }

    if (e.results > e.optimalHigh) {
      return "High";
    }

    return "Normal";
  };

  const graphDataHandler = (e: any) => {
    const testData = {
      labels: e.map((res: any) => {
        return formatDate(res.userTestDate.reportDate);
      }),
      datasets: [
        {
          label: "Blood test Result",
          data: e.map((res: any) => {
            return res.results;
          }),
          fill: true,
          opacity: "0.5",
          borderColor: "#27AE60",
          pointRadius: 5,
          pointHoverRadius: 5,
          padding: 1,
        },
      ],
    };

    return testData;
  };

  const groupBy = function (xs: any, key: any) {
    return xs.reduce(function (rv: any, x: any) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const searchingItem = (words?: any) => {
    if (words.length > 0) {
      const names = Object.keys(progressData)
        .filter((key) => key.toLowerCase().includes(words.toLowerCase()))
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: progressData[key],
          });
        }, {});
      setProgressData(names);
      if (Object.keys(names).length != 0) {
        setProgressData(names);
      } else {
        setProgressData(initialData);
      }
    } else {
      setProgressData(initialData);
    }
  };

  const getAllTestDetails = () => {
    if (userId) {
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/userAllTestDetails?userId=${userId}`,
          {
            headers: {
              Authorization: sessionStorage.getItem("AUTH_TOKEN") || "",
            },
          }
        )
        .then((response: any) => {
          setLoading(false);
          const resData = groupBy(response.data.data, "biomarker");
          setProgressData(resData);
          setInitialData(resData);
          setDateData(Object.values(resData)[0]);
          setGetData(Object.values(resData)[0]);
          setGraphTitle(Object.keys(resData)[0]);
        })
        .catch((error: any) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getAllTestDetails();
  }, [userId]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="MainPannel bgclr space">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-8 col-xl-7 bloodResultSec">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="heading3">
                      <Link to="/checkup-result">
                        <img alt=" " src={BackImg} className="arrow" />
                      </Link>
                      Blood Results
                    </h3>
                  </div>
                  <div className="col-md-12">
                    <div className="parameter-table table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">
                              <div className="search-bar2">
                                <input
                                  type="text"
                                  placeholder="Find"
                                  onChange={(e: any) =>
                                    searchingItem(e.target.value)
                                  }
                                />
                              </div>
                            </th>
                            {progressData ? (
                              dateData.map((item: any, index: any) => {
                                return (
                                  <th scope="col">
                                    {formatDate(item.userTestDate.reportDate)}
                                  </th>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {progressData ? (
                            Object.keys(progressData).map(
                              (item: any, index: any) => {
                                return (
                                  <tr
                                    className={`${
                                      graphTitle == item
                                        ? `isActive-heading `
                                        : ``
                                    }`}
                                    onClick={() => showModelOnClick(item)}
                                  >
                                    <th scope="row">{item}</th>
                                    {progressData[item] &&
                                      progressData[item].map(
                                        (item1: any, index: any) => {
                                          return (
                                            <td key={index}>
                                              {`${item1.results} ${item1.unit}`}
                                            </td>
                                          );
                                        }
                                      )}
                                  </tr>
                                );
                              }
                            )
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-4 col-xl-5 hide-sm">
                <div className="admininner-sec AdminBorder">
                  <div className="innerpro">
                    <div className="divimg ">
                      {progressData && getData ? (
                        <>
                          <GraphShared
                            data={graphDataHandler(getData)}
                            graphTitle={graphTitle}
                            scaleX
                            scaleY
                          />

                          <ModelPopUp
                            graphTitle={graphTitle}
                            showValue={showModel}
                            showMethod={() => setShowModel(!showModel)}
                            graphData={graphDataHandler(getData)}
                            children={true}
                            data={getData}
                            scaleY={true}
                            scaleX={true}
                          ></ModelPopUp>

                          <div className="bt-table table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col" className="FixedHeading">Date</th>
                                  {getData &&
                                    getData.map((item1: any, index: any) => {
                                      const { userTestDate } = item1;
                                      return (
                                        <th scope="col">
                                          {formatDate(userTestDate.reportDate)}
                                        </th>
                                      );
                                    })}
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th className="FixedHeading">Result</th>
                                  {getData &&
                                    getData.map((item1: any, index: any) => {
                                      const { results, unit } = item1;
                                      return (
                                        <td className="resultTable">{`${results} ${unit}`}</td>
                                      );
                                    })}
                                </tr>
                                <tr>
                                  <th className="FixedHeading">Optimal range </th>
                                  {getData &&
                                    getData.map((item1: any, index: any) => {
                                      const { optimalHigh, optimalLow } = item1;
                                      return (
                                        <td className="resultTable optimal-range">{`${optimalLow} - ${optimalHigh}`}</td>
                                      );
                                    })}
                                </tr>
                                <tr>
                                  <th className="FixedHeading">Status</th>
                                  {getData &&
                                    getData.map((item1: any, index: any) => {
                                      return (
                                        <td
                                          className={
                                            statusFlag(item1) === "Normal"
                                              ? "greenclr"
                                              : "redclr"
                                          }
                                        >
                                          {statusFlag(item1)}
                                        </td>
                                      );
                                    })}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default BloodResultPage;
