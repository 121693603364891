import "./ImportUser.scss";
import "../../../src/App.css";
import { useState} from "react";
import axios from "axios";
import { ToastOnFailure, ToastOnSuccess } from "../../shared/Toast/ToastMsg";
import { Form } from "react-bootstrap";
import Spinner from "../../shared/Spinner/Spinner";

const ImportPage = () => {
  const [fileSelect, setFileSelect] = useState<any>();
  const [uploadFileType,setUploadFileType] = useState('UserDetails');
  const [loading ,setLoading] = useState(false);

  const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;
    if (!fileList) return;
    setFileSelect(fileList[0]);
};

  const handleUpload = () => {   
    if(fileSelect){
        setLoading(true);
        const formData = new FormData();
        formData.append("csv",fileSelect)
        formData.append("uploadFileType", `${uploadFileType}`)
        axios.post(`${process.env.REACT_APP_BASE_URL}/userDataImport`,formData,{
          headers: {
            Authorization: sessionStorage.getItem("AUTH_TOKEN") || "",
          },
        }).then((res)=>{
          setLoading(false);
          ToastOnSuccess(res.data.message);
          setFileSelect('');
        }).catch(err=>{
          console.log(err)
          setLoading(false);
          ToastOnFailure(err.response.data.message)
        })

    }else{
      ToastOnFailure("please select the file ")
    }
   
  };
  const GetCheckBox = (e:any)=>{
    setUploadFileType(e.target.id);
  }

  return (
    <>
      <div className="MainPannel bgclr importBg">
        <div className="containers">
          <div className="row">{
            loading ? (<Spinner />) : (
              <div className="col-md-12">
              <div className="ImportPage">
                <div className="ImportHeading">
                  <h2 className="CSVHeading ">Import your CSV file here...</h2>
                </div>
                <div className="ImportField">
                  <div className="file-input">
                    <input
                      type="file"
                      name="file-input"
                      id="file-input"
                      className="file-input__input InputFile"
                      onChange={(e: any) => handleImageChange(e)}
                    />

                    <div className="form-radio-btn">
                    <span className="file-type-text"
                      onChange={(e:any)=>GetCheckBox(e)}
                    >Mode : 
                    <Form.Check
                      inline
                      label="UserDetails"
                      name="group1"
                      type='radio'
                      id="UserDetails"
                      defaultChecked={uploadFileType===`UserDetails` ? true : false}
                    />
                    <Form.Check
                      inline
                      label="Kpi"
                      name="group1"
                      type='radio'
                      id="Kpi"
                      defaultChecked={uploadFileType!==`UserDetails` ? true : false}
                    />
                    </span>
                    </div>
                    
                    <button
                      className="file-input__label"
                      type="submit"
                      onClick={() => handleUpload()}
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="upload"
                        className="svg-inline--fa fa-upload fa-w-16"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                        ></path>
                      </svg>

                      <span>Upload file</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            )  
          }
          </div>
        </div>
      </div>
    </>
  );
};
export default ImportPage;
