import { useNavigate } from "react-router-dom";
import GraphImg from "../../../assets/img/Frame25.png";
import ListImg from "../../../assets/img/List.png";
import { useEffect, useState } from "react";
import GraphShared from "../../../shared/GraphShared/GraphShared";
import Dropdown from "react-bootstrap/Dropdown";
import { DropdownButton } from "react-bootstrap";
import "./bloodTestMain.style.scss";
import DownArrow from "../../../assets/img/downArrow.png";
import BackImg from "../../../assets/img/Back.png";
import infoImg from "../../../assets/img/info.png";
import ModelReport from "../../../shared/models/ModelReport/ModelReport";
import axios from "axios";
import Spinner from "../../../shared/Spinner/Spinner";
import EmptyDataSign from "../../../assets/img/empty-data.svg";
import { formatDate } from "../../../util/util";

interface IProps {
  userId: any;
  selectedUserName:string;
}

const BloodTestMainResult = ({ userId,selectedUserName }: IProps) => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [updatedDate, setUpdatedDate] = useState<any>("");
  const [showCheckedItem, setShowCheckedItem] = useState<any>(`Checkup Result`);
  const [getDropSelect, setDropSelect] = useState("");
  const [testDates, setCheckUpDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bloodResult, setBloodResult] = useState([]);
  const [progressData, setProgressData] = useState<any>();
  const [showArray, setShowArray] = useState<any>();
  const [searchInput, setInputSearch] = useState("");

  const navigateWithData = () => {
    navigate("/checkup-result-detail");
  };

  const tabChanger = (title: string) => {
    setShowCheckedItem(title);
  };

  /***  get all  dates on the behalf of date*/

  const getAllCheckUpDates = () => {
    if (userId) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/usertestDates?userId=${userId}`, {
          headers: {
            Authorization: sessionStorage.getItem("AUTH_TOKEN") || "",
          },
        })
        .then((response: any) => {
          setCheckUpDates(response.data.data);
          setUpdatedDate(response.data.data[0].reportDate);
          setDropSelect(`Checkup №${response.data.data.length} ${response.data.data[0].reportDate}`);
          getBloodResultDataByDate(response.data.data[0].reportDate);
          getAllTestDetails();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const handleSelect = (e: any,index:any) => {
    const defualtData = `Checkup №${index+1} ${e.reportDate}`;
    setDropSelect(defualtData);
    setUpdatedDate(e.reportDate);
    if (updatedDate !== e) getBloodResultDataByDate(e.reportDate);
  };

  /*** progress-section   get all test details on the behalf of date*/

  const getAllTestDetails = () => {
    if (userId) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/userAllTestDetails?userId=${userId}`,
          {
            headers: {
              Authorization: sessionStorage.getItem("AUTH_TOKEN") || "",
            },
          }
        )
        .then((response: any) => {
          const resData = groupBy(response.data.data, "biomarker");
          setProgressData(resData);
          let array = [];
          for (const property in resData) {
            array.push({ graphShow: false, listShow: true });
          }

          if (array.length > 0) {
            array[0].graphShow = true;
          }
          setShowArray(array);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };

  /*** call the api on the behalf of date change */

  const getBloodResultDataByDate = (date: any) => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/bloodResults?date=${date}&userId=${userId}`,
        {
          headers: {
            Authorization: sessionStorage.getItem("AUTH_TOKEN") || "",
          },
        }
      )
      .then((response: any) => {
        setLoading(false);
        setBloodResult(response.data.data);
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
      });
  };

  /** Status flag function */

  const statusFlag = (e: any) => {
    if (e.results < e.unfavourableLow) {
      return "Critical Low";
    }

    if (e.results < e.optimalLow) {
      return "Low";
    }

    if (e.results > e.unfavourableHigh) {
      return "Critical High";
    }

    if (e.results > e.optimalHigh) {
      return "High";
    }

    return "Normal";
  };

  /*** slider handler of input slider */

  const inputSliderHandler = (e: any, value: number) => {
    value = value === null ? 0 : value;

    if (value < e.unfavourableLow) {
      return 5;
    }

    if (value < e.optimalLow) {
      return 25;
    }

    if (value > e.unfavourableHigh) {
      return 95;
    }

    if (value > e.optimalHigh) {
      return 75;
    }

    return 50;
  };

  const showStatus = (e: any, key: any, value: any) => {
    return showArray[Object.keys(e).indexOf(key)][value];
  };

  const showHandler = (e: any, key: any, value: any) => {
    const array = [...showArray];
    array[Object.keys(e).indexOf(key)][value] =
      !array[Object.keys(e).indexOf(key)][value];
    setShowArray(array);
  };

  const graphDataHandler = (e: any) => {
    const testData = {
      labels: e.map((res: any) => {
        return formatDate(res.userTestDate.reportDate);
      }),
      datasets: [
        {
          label: "Result",
          data: e.map((res: any) => {
            return res.results;
          }),
          fill: true,
          borderColor: "#27AE60",
        },
      ],
    };

    return testData;
  };

  const groupBy = function (xs: any, key: any) {
    return xs.reduce(function (rv: any, x: any) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  /*** slider handler of input slider */

  const sliderMaxValue = (
    low: any,
    high: any,
    value: any,
    unfavourableLow: any,
    unfavourableHigh: any
  ) => {
    return value + low + high + unfavourableLow + unfavourableHigh;
  };

  /** reduce the rate of hits on keyPress  */

  useEffect(() => {
    const getData = setTimeout(() => {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/bloodResults?date=${updatedDate}&userId=${userId}&search=${searchInput}`,
          {
            headers: {
              Authorization: sessionStorage.getItem("AUTH_TOKEN") || "",
            },
          }
        )
        .then((response) => {
          setBloodResult(response.data.data);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }, 500);

    return () => clearTimeout(getData);
  }, [searchInput]);


  const boxColourHandler = (e: any) => {
    if (statusFlag(e) === "Critical Low" || statusFlag(e) === "Critical High") {
      return "boxred";
    }

    if (statusFlag(e) === "Low" || statusFlag(e) === "High") {
      return "boxorg";
    }
    return "boxgreen";
  };

  const colourHandler = (e: any) => {
    if (statusFlag(e) === "Critical Low" || statusFlag(e) === "Critical High") {
      return "redclr";
    }

    if (statusFlag(e) === "Low" || statusFlag(e) === "High") {
      return "orgclr";
    }
    return "greenclr";
  };


  const getPdfDownload = () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_BASE_URL}/pdf?userId=${userId}`;
    axios
      .get(url, {
        responseType: "arraybuffer",
        headers: {
          Authorization: sessionStorage.getItem("AUTH_TOKEN") || "",
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      })
      .then((response:any) => {
        setLoading(false)
        const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${selectedUserName}Report.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
      }).catch((err)=>{
        console.log("Error", err);
        setLoading(false);
      })
  };


  /** Date Formatter  */


  /** get checkup dates behalf on userId changes  */
  useEffect(() => {
    getAllCheckUpDates();
    setShowCheckedItem(`Checkup Result`)
  }, [userId]);
  
  return (
    <>
      <div className="MainPannel spaceN2 admin-panal adminvh">
        <div className="container-fluid">
          <div className="row d-lg-block ">
            <div className="col-md-12">
              {showCheckedItem == "Checkup Result" ? (
                <div className="headdiv">
                  <h3 className="heading3">Blood Test Results</h3>
                </div>
              ) : (
                <div>
                  <div className="headdiv">
                    <h3 className="heading4">Checkup Result</h3>

                    <a className="heading3" href="#">
                      <img src={infoImg} className="arrow" />
                      <a
                        className="btn-greeen"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => getPdfDownload()}
                      >
                        Create a Report
                      </a>
                    </a>
                  </div>
                  <p className="ObserveHeading">
                    Observe the progress across all your checkups results
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        {!userId && !loading ? (
          <img
            className="no-data-found-txt"
            src={EmptyDataSign}
            alt="empty data img"
          />
        ) : loading || !updatedDate ? (
          <Spinner />
        ) : (
          <div className="progressSec">
            <div className="container-fluid">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    onClick={() => tabChanger("Checkup Result")}
                  >
                    Checkups
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    onClick={() => tabChanger("Blood Results")}
                  >
                    Progress
                  </button>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="row justify-content-between">
                    <div className="col-lg-4 col-md-6 col-sm-12 CheckupSec">
                      <img className="downIcon" src={DownArrow} alt="" />
                      <DropdownButton
                        title={`${
                          getDropSelect.substring(0, 11) +
                          formatDate(getDropSelect.substring(11).toLocaleLowerCase())                          
                        }`}
                        id="dropdown-menu-align-right"
                        className="default-date-blood-result"
                      >
                        {testDates ? (
                          Object.values(testDates).map(
                            (item: any, index: number, array:any) => {
                              return (
                                <Dropdown.Item
                                  eventKey={item.reportDate}
                                  key={index}
                                  onClick={() => handleSelect(item, (array.length - 1 - index ))}
                                >
                                  <span className="checkupHeading">
                                  Checkup №{array.length - 1 - index +1}
                                  </span>{" "}
                                  <a className="default-date-blood-result ms-2">
                                    {formatDate(item.reportDate)}
                                  </a>
                                </Dropdown.Item>
                              );
                            }
                          )
                        ) : (
                          <Dropdown.Item>{getDropSelect}</Dropdown.Item>
                        )}
                      </DropdownButton>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="search-bar">
                        <input
                          type="text"
                          placeholder="Search"
                          onChange={(e: any) => setInputSearch(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row blood-result mt-5">
                    {bloodResult ? (
                      bloodResult.map((item: any, index: any) => {
                        const {
                          biomarker,
                          optimalHigh,
                          optimalLow,
                          results,
                          unit,
                          unfavourableLow,
                          unfavourableHigh,
                        } = item;
                        return (
                          <div
                            className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12 mbb"
                            key={index}
                          >
                            <div className="component-box">
                              <div
                                className="cursorPointer"
                                onClick={() => navigateWithData()}
                              >
                                <h5 className="boxtitle5">{biomarker }</h5>
                                <a
                                  className={`boxclr ${boxColourHandler(item)}`}
                                >
                                  {statusFlag(item)}
                                </a>
                                <p className="para">
                                  Optimal reference range of the laboratory{" "}
                                  {`${optimalLow}-${optimalHigh} ${unit}`}
                                </p>
                                <h4 className="numbertile">{`${results} ${unit}`}</h4>
                              </div>

                              <div className="inputslider">
                                <input
                                  style={{
                                    background: `linear-gradient(
                                      90deg,
                                      #e65050 0%,
                                      #e69850 20%,
                                      #50e67a 40%,
                                      #50e67b 60%,
                                      #e69850 80%,
                                      #e65050 100%
                                    )`,
                                  }}
                                  type="range"
                                  max={100}
                                  min={0}
                                  value={inputSliderHandler(item,results)}
                                  className="slider"
                                  id="red_slider"
                                  onChange={() => {
                                    console.log();
                                  }}
                                />
                                <p>
                                  <span className="low inputtext">Low</span>
                                  <span className="high inputtext">High</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <h2>No Record Found</h2>
                    )}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div className="progressSec">
                    {progressData ? (
                      Object.keys(progressData).map((item: any, index: any) => {
                        return (
                          <div className="innerpro progressBG progress-sec-child progressBG" key={index}>
                            <div className="tb d-flex">
                              <h5>{item}
                              <span className="m-2">[{progressData[item][0].unit}]</span>
                              </h5>
                              <div className="frame-icon">
                                <img
                                  src={GraphImg}
                                  onClick={() =>
                                    showHandler(progressData, item, "graphShow")
                                  }
                                />
                                <img
                                  src={ListImg}
                                  onClick={() =>
                                    showHandler(progressData, item, "listShow")
                                  }
                                />
                              </div>
                            </div>
                            <div className="divimg">
                              {progressData[item] &&
                              progressData[item].length > 0 &&
                              showStatus(progressData, item, "graphShow") ? (
                                <GraphShared
                                  scaleX={true}
                                  scaleY={false}
                                  data={graphDataHandler(progressData[item])}
                                />
                              ) : null}
                            </div>
                            <div className="bt-table table-responsive">
                              {showStatus(progressData, item, "listShow") ? (
                                <table className="table">
                                  <tbody>
                                    <tr>
                                      <th scope="col">Date</th>
                                      {progressData[item] &&
                                        progressData[item].map(
                                          (item1: any, index: any) => {
                                            const { userTestDate } = item1;
                                            return (
                                              <th scope="col" key={index}>
                                                {formatDate(userTestDate.reportDate)}
                                              </th>
                                            );
                                          }
                                        )}
                                    </tr>
                                    <tr>
                                      <th>Result</th>
                                      {progressData[item] &&
                                        progressData[item].map(
                                          (item1: any, index: any) => {
                                            const { results, unit } = item1;
                                            return (
                                              <td
                                                key={index}
                                                className="resultTable"
                                              >{`${results} ${unit}`}</td>
                                            );
                                          }
                                        )}
                                    </tr>
                                    <tr>
                                      <th>Optimal range</th>
                                      {progressData[item] &&
                                        progressData[item].map(
                                          (item1: any, index: any) => {
                                            const { optimalHigh, optimalLow } =
                                              item1;
                                            return (
                                              <td
                                                key={index}
                                                className="resultTable-optimal optimal-range"
                                              >{`${optimalLow} - ${optimalHigh}`}</td>
                                            );
                                          }
                                        )}
                                    </tr>
                                    <tr>
                                      <th>Status</th>
                                      {progressData[item] &&
                                        progressData[item].map(
                                          (item1: any, index: any) => {
                                            return (
                                              <td
                                                key={index}
                                                className={colourHandler(item1)}
                                              >
                                                {statusFlag(item1)}
                                              </td>
                                            );
                                          }
                                        )}
                                    </tr>
                                  </tbody>
                                </table>
                              ) : null}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {showCheckedItem ? (
        <ModelReport show={modalShow} onHide={() => setModalShow(false)} />
      ) : null}
    </>
  );
};

export default BloodTestMainResult;
