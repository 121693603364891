import "./edit.style.scss";
import { Button, Offcanvas } from "react-bootstrap";
import { useState } from "react";
import { ToastOnFailure, ToastOnSuccess } from "../../Toast/ToastMsg";
import axios from "axios";

interface IProps {
  show?: boolean;
  data?: any;
  setShow?: any;
  flagUpdated?:any;
  setFlagUpdated?: any;
}
const EditKpiDetail: React.FC<IProps> = ({
  show,
  setShow,
  data,
  flagUpdated,
  setFlagUpdated,
}) => {
  
  const [userDetails, setUserDetails] = useState([
    {
      value: data?.isActionSelected == "AddNewRow" ? "" : data?.selectedData[0],
      name: "KPI Metric",
    },
    {
      value: data?.isActionSelected == "AddNewRow" ? "" : data?.selectedData[1],
      name: "Now",
    },
    {
      value: data?.isActionSelected == "AddNewRow" ? "" : data?.selectedData[2],
      name: "Phase 1",
    },
    {
      value: data?.isActionSelected == "AddNewRow" ? "" : data?.selectedData[3],
      name: "Phase 2",
    },
    {
      value: data?.isActionSelected == "AddNewRow" ? "" : data?.selectedData[4],
      name: "Phase 3",
    },
    {
      name: "Unit",
      value: data?.isActionSelected == "AddNewRow" ? "" : data?.selectedData[5],
    },
  ]);

/**
 * 
 * @param e 
 * @param index 
 */

  const onUpdateEditField = (e: any, index: number) => {

    let newArr: { value: any; name: string }[] = [];
     userDetails?.map((item, index1) => {
      if (index === index1) {
        if (item.name == "KPI Metric") {
          if(e.target.value.match("^[a-zA-Z0-9]*$")){
            item.value = e.target.value;
          }
        
        } else if (item.name =="Now" || item.name =="Phase 1" ||item.name =="Phase 2" || item.name =="Phase 3") {
          if(e.target.value.match(/^\d*\.?\d*$/))item.value = e.target.value;
        } else if (item.name == "Unit") {
          item.value = e.target.value;
        }
      }
      newArr.push(item);
    });
    setUserDetails(newArr);
  };

  /**** update kpi details  */
  const updateKpiDetails = () => {
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/kpi`,
        {
          kpiID: data.ItemId,
          userId: data.userId,
          kpiMetricDetail: userDetails["0"].value,
          now: Number(userDetails["1"].value),
          phase1: Number(userDetails["2"].value),
          phase2: Number(userDetails["3"].value),
          phase3: Number(userDetails["4"].value),
          unit: userDetails["5"].value,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("AUTH_TOKEN") || "",
          },
        }
      )
      .then((response: any) => {
        ToastOnSuccess(response.data.message);
        setShow(false);
        setFlagUpdated(!flagUpdated);
      })
      .catch((error) => {
        console.log(error);
        ToastOnFailure(error.response.data.message);
      });
  };
  /** Add New Row  in kpi Field  */
  const addNewRow = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/kpi`,
        {
          userId: data.userId,
          kpiMetricDetail: userDetails["0"].value,
          now: Number(userDetails["1"].value),
          phase1: Number(userDetails["2"].value),
          phase2: Number(userDetails["3"].value),
          phase3: Number(userDetails["4"].value),
          unit: userDetails["5"].value,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("AUTH_TOKEN") || "",
          },
        }
      )
      .then((response) => {
        ToastOnSuccess(response.data.message);
        setShow(false);
        setFlagUpdated(!flagUpdated);

      })
      .catch((error) => {
        console.log(error);
        setShow(false);
        ToastOnFailure(error.response.data.message);
      });
  };

/**
 * disable input sepcific phase 
 * @param phaseName 
 * @returns  weather we want to disable specific phase or not 
 */

  const disabledSpecificPhases = (phaseName:any)=>{
     return data?.isActionSelected == "Edit Row" && phaseName?.name=="Now" && phaseName?.name=="Phase 1" && phaseName?.name =="Phase 2" && phaseName?.name=="Phase 3" ? true :false
  } 

  return (
    <>
      <Offcanvas
        show={show}
        onHide={() => setShow(false)}
        placement="end"
        className="offcanvas-edit-kpi"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {data?.isActionSelected == "AddNewRow" ? `Add New Row` : `Edit Row`}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="position-relative">
          <>
            <div className="row">
              <div className="col-6">
                {userDetails?.map((item, index) => {
                  return (
                    <div
                      className="input-group input-group-sm mb-3 "
                      key={index}
                    >
                      <span
                        className="input-group-text w-100"
                        id="inputGroup-sizing-sm"
                      >
                        {item.name}
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="col-6">
                {userDetails?.map((item, index) => {
                  return (
                    <div
                      className="input-group input-group-sm mb-3"
                      key={index}
                    >
                      <input
                        type="text"
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-sm"
                        value={item.value}
                        disabled={disabledSpecificPhases(item)}
                        onChange={(e: any) => onUpdateEditField(e, index)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="fixed-bottom-content">
              <Button
                className="fixed-bottom-content-btn"
                variant="light"
                onClick={() => setShow(false)}
              >
                Close
              </Button>
              <Button
                className="fixed-bottom-content-btn"
                variant="dark"
                // disabled={}
                onClick={() =>
                  data.isActionSelected == "editRow"
                    ? updateKpiDetails()
                    : addNewRow()
                }
              >
               {data.isActionSelected == "editRow" ?  `Update` : `Save`}
              </Button>
            </div>
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default EditKpiDetail;
