import axios from "axios";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Header from "../component/header/Header";
import ImportPage from "../component/ImportUser/ImportUser";
import Login from "../component/Login/Login";
import SideBar from "../component/SiderBar/SideBar";
import AdminPanel from "../pages/AdminPanel/AdminPanel";
import BloodResultPage from "../pages/BloodResult/BloodTestChild/BloodResult";
import BloodTestMainResult from "../pages/BloodResult/BloodTestMain/BloodTestMainResult";
import KpiPage from "../pages/KPI/Kpi.Page";
import Spinner from "../shared/Spinner/Spinner";
import { capitalizeFirstLetter, compareName } from "../util/util";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";


const RoutePath = () => {
  const [selectedUser, SetSelectedUser] = useState<any>(1);
  const [selectedUserName, setSelectedUserName] = useState<any>(
  );
  const [isVerified, setVerified] = useState(
    sessionStorage.getItem("AUTH_TOKEN") ? true : false
  );
  const [loading, setLoading] = useState(false);
  const [userId,setUserId] =useState();
  const [userData,setUserData]= useState<any>([]);
  

  const navigate = useNavigate();
  const setSigninStatus = (status: boolean) => {
    setVerified(status);
    if (status) {
      navigate("/dashboard");
      getAllUser();
    }
  };
  const getAllUser = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/GetAllUser`, {
        headers: {
          Authorization: sessionStorage.getItem("AUTH_TOKEN") || "",
        },
      })
      .then((response) => {
        setLoading(false);
        setUserData(response.data.data.sort(compareName))
        setUserId(response.data.data.sort(compareName)['0'].id)
        setLoading(false)
        setSelectedUserName(capitalizeFirstLetter(response.data.data[`0`].userDetail.firstName) + "  " +capitalizeFirstLetter(response.data.data[`0`].userDetail.lastName));
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)
      });
  };

  
  const checkIndex = (id: any) => {
    const userFindById = userData.find((item:any)=>item.id ===id);
    SetSelectedUser(userFindById) 
    setUserId(id);
    setSelectedUserName(capitalizeFirstLetter(userFindById.userDetail.firstName) + "  " +capitalizeFirstLetter(userFindById.userDetail.lastName));
  };
  

useEffect(()=>{
  getAllUser();
},[])

  return (

    <div>
      {isVerified ? (
        <Header
          checkIndex={checkIndex}
          selectedUser={selectedUser}
          selectedUserName={selectedUserName}
          setSigninStatus={setSigninStatus}
          userData={userData}
          userId={userId}
        />
      ) : (
        <></>
      )}

      <div className={isVerified ? "wrapper adminwraper" : ""}>
        {isVerified ? (
          <SideBar
            checkIndex={checkIndex}
            selectedUser={selectedUser}
            selectedUserName={selectedUserName}
            userData={userData}
            userId={userId}
          />
        ) : (
          <></>
        )}

        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute isVerified={isVerified} redirectPath="/login">
               {loading ? (<Spinner />) : (<AdminPanel userId={userId} />) } 
              </ProtectedRoute>
            }
          />

          <Route
            path="/kpi"
            element={
              <ProtectedRoute isVerified={isVerified} redirectPath="/login">
                <KpiPage userId={userId}/>
              </ProtectedRoute>
            }
          />

          <Route
            path="/checkup-result"
            element={
              <ProtectedRoute isVerified={isVerified} redirectPath="/login">
                <BloodTestMainResult userId={userId} selectedUserName={selectedUserName} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/checkup-result-detail"
            element={
              <ProtectedRoute isVerified={isVerified} redirectPath="/login">
                <BloodResultPage userId={userId}/>
              </ProtectedRoute>
            }
          />
          <Route
            path="/login"
            element={
              <PublicRoute isVerified={isVerified} redirectPath="/dashboard">
                <Login setSigninStatus={setSigninStatus} />
              </PublicRoute>
            }
          />
          <Route
            path="/ImportUser"
            element={
              <ProtectedRoute isVerified={isVerified} redirectPath="/login">
                <ImportPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default RoutePath;
